import React, {
  createContext, useContext, useEffect, useState, useRef, useCallback, useMemo,
} from 'react';
import { Connection } from 'autobahn-browser';
import { getSocketUrl } from '../../util/request';
import { getSubdomain } from '../../util/subdomain';
import api from './socketApi';

const SOCKET_REALM = 'mywork';

const SUBDOMAIN = getSubdomain();

const SUBSCRIPTION_TOPICS = [
  'user.status',
  'message.update',
];

const SocketContext = createContext();

const initConnection = registerSubscriptions => {
  if (process.env.REACT_APP_DISABLE_WEBSOCKET === 'true') {
    return null;
  }

  const connection = new Connection({
    url: getSocketUrl(SUBDOMAIN),
    realm: SOCKET_REALM,
  });

  // TODO: We probably need som keepalive method
  connection.onopen = (session, details) => {
    api.registerSocket(session.id);
    registerSubscriptions(session);
  };

  connection.onclose = (reason, details) => {
    // TODO: handle connection lost
  };

  connection.open();
  return connection;
};

const SocketProvider = ({ children }) => {
  const [connection, setConnection] = useState();
  const dispatchersRef = useRef([]);

  const registerDispatcher = useCallback(f => {
    dispatchersRef.current = [...dispatchersRef.current, f];
  }, []);

  const unregisterDispatcher = useCallback(f => {
    dispatchersRef.current = dispatchersRef.current.filter(g => f !== g);
  }, []);

  useEffect(() => {
    const dispatchAll = action => dispatchersRef.current.forEach(
      dispatch => dispatch(action),
    );

    // WAMP topics become action types
    const registerSubscriptions = session => SUBSCRIPTION_TOPICS.forEach(type => session
      .subscribe(type, ([{ message }]) => dispatchAll({ type, message })));

    const c = initConnection(registerSubscriptions);
    setConnection(c);
  }, [setConnection]);

  const context = useMemo(() => ({
    connection,
    registerDispatcher,
    unregisterDispatcher,
  }), [connection, registerDispatcher, unregisterDispatcher]);

  return (
    <SocketContext.Provider value={context}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketProvider = () => useContext(SocketContext);

export default SocketProvider;
