import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import React, { useState, useEffect, useCallback } from 'react';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';

// Service worker registration seems to be particular about the way it's started. To be able to
// init it in a component we have to put it in a promise.
const foundUpdate = new Promise(resolve => {
  if (process.env.NODE_ENV === 'production') {
    serviceWorkerRegistration.register({ onUpdate: resolve });
  }
});

// Inspired by:
// https://dev.to/daniellycosta/showing-new-version-available-notification-on-create-react-app-pwas-3jc9
const UpdateNotifier = () => {
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const updateServiceWorker = useCallback(() => {
    waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setNewVersionAvailable(false);
    window.location.reload();
  }, [waitingWorker]);

  const refreshAction = useCallback(() => (
    <Button
      size="small"
      onClick={updateServiceWorker}
    >
      {t('updater.refresh')}
    </Button>
  ), [t, updateServiceWorker]);

  useEffect(() => {
    if (newVersionAvailable) {
      enqueueSnackbar(t('updater.newVersionAvailable'), {
        persist: true,
        variant: 'success',
        action: refreshAction(),
      });
    }
  }, [enqueueSnackbar, newVersionAvailable, refreshAction, t]);

  useEffect(() => {
    foundUpdate.then(registration => {
      setWaitingWorker(registration.waiting);
      setNewVersionAvailable(true);
    });
  }, []);

  return null;
};

export default UpdateNotifier;
