import React, { createContext, useContext, useMemo } from 'react';

const FieldRegistryContext = createContext();

const FieldRegistryProvider = ({ children, registry, t }) => {
  const value = useMemo(() => ({ registry, t }), [registry, t]);
  return (
    <FieldRegistryContext.Provider value={value}>
      {children}
    </FieldRegistryContext.Provider>
  );
};

export const useFieldRegistry = () => useContext(FieldRegistryContext);

export default FieldRegistryProvider;
