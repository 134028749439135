import request, { getBaseUrl } from '../../util/request';
import { setCsrfToken, clearCsrfToken } from '../../util/csrf';
import { setSubdomain } from '../../util/subdomain';

// Load settings from specific instance (subdomain).
const instance = subdomain => {
  const url = `${getBaseUrl(subdomain)}/settings`;

  return request(url, {
    isLocalRequest: false,
  }).then(settings => {
    setSubdomain(subdomain);
    return settings;
  });
};

const login = body => request('login', {
  method: 'POST',
  body: JSON.stringify(body),
})
  .then(({ csrf_token: token, ...rest }) => {
    setCsrfToken(token);
    return rest;
  });

const logout = () => request('logout', {
  method: 'POST',
  parseJSON: false,
}).then(response => {
  clearCsrfToken();
  return response;
});

// Get user session.
const authenticate = () => request('authenticate', {
  method: 'POST',
}).then(({ csrf_token: token, ...rest }) => {
  setCsrfToken(token);
  return rest;
});

const updateAccount = body => request('users', {
  method: 'PUT',
  body: JSON.stringify(body),
});

const userApi = {
  authenticate,
  instance,
  login,
  logout,
  updateAccount,
};

export default userApi;
