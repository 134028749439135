import React, { createContext, useContext, useMemo } from 'react';
import useSocketReducer from '../../util/useSocketReducer';

const BadgesContext = createContext();

const emptyState = {
  messages: [],
};

const messagesReducer = (state, action) => {
  switch (action.type) {
    case 'message.update': {
      return {
        ...state,
        messages: [
          ...state.messages,
          action.message,
        ],
      };
    }
    case 'removeThread':
      return {
        ...state,
        messages: state.messages.filter(m => m.thread_id !== action.threadId),
      };
    default:
      return state;
  }
};

const BadgesProvider = ({ children }) => {
  const [{ messages }, dispatch] = useSocketReducer(messagesReducer, emptyState);

  const messagesPerThread = useMemo(() => messages.reduce(
    (result, message) => (
      {
        ...result,
        [message.thread_id]: result[message.thread_id] ? result[message.thread_id] + 1 : 1,
      }),
    {},
  ), [messages]);

  const context = useMemo(() => {
    const removeThread = threadId => dispatch({ type: 'removeThread', threadId });

    return ({
      messageCount: messages.length,
      removeThread,
      messagesPerThread,
    });
  }, [dispatch, messages.length, messagesPerThread]);

  return (
    <BadgesContext.Provider value={context}>
      {children}
    </BadgesContext.Provider>
  );
};

export const useBadges = () => useContext(BadgesContext);

export default BadgesProvider;
