import request from '../../util/request';

const registerSocket = socketId => request('sockets', {
  method: 'PUT',
  body: JSON.stringify({ socketId }),
});

const socketApi = {
  registerSocket,
};

export default socketApi;
