import React, { forwardRef } from 'react';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import { useFormContext } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import Spinner from '../../components/Spinner';

const useStyles = makeStyles(theme => ({
  button: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const SubmitButton = forwardRef((
  {
    children, className, disableIfNotDirty = true, ...props
  },
  ref,
) => {
  const classes = useStyles();
  const { formState: { isSubmitting, isValid, isDirty } } = useFormContext();

  const buttonClass = clsx(classes.button, className);

  return (
    <Button
      type="submit"
      disabled={isSubmitting || !isValid || (disableIfNotDirty && !isDirty)}
      {...props}
      ref={ref}
      className={buttonClass}
    >
      { children }
      {isSubmitting ? <Spinner className={classes.buttonProgress} size={24} /> : null}
    </Button>
  );
});

export default SubmitButton;
