import React, {
  createContext, useContext, useEffect, useState, useMemo,
} from 'react';
import SpinnerOverlay from '../../components/SpinnerOverlay';
import request from '../../util/request';
import { useAuth } from '../AuthProvider';

const globalRequests = [
  { key: 'permissions', route: 'permissions', permission: 'administer_users' },
  { key: 'roles', route: 'roles', permission: 'administer_users' },
  { key: 'users', route: 'users', permission: 'authenticated' },
  { key: 'groups', route: 'groups', permission: 'authenticated' },
  { key: 'workphases', route: 'workphases', permission: 'authenticated' },
];

const AppStateContext = createContext();

export const useAppState = () => useContext(AppStateContext);

const AppStateProvider = ({ children }) => {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(true);
  const { can } = useAuth();

  const reload = key => {
    const { route } = globalRequests.find(req => req.key === key);
    return request(route)
      .then(response => setState(previousState => ({ ...previousState, [key]: response })));
  };

  useEffect(() => {
    const promises = globalRequests
      .filter(
        ({ permission }) => can(permission),
      )
      .map(
        ({ key, route }) => request(route).then(response => ({ key, response })),
      );

    Promise.all(promises).then(responses => {
      const globalState = responses.reduce(
        (carry, { key, response }) => ({ ...carry, [key]: response }),
        {},
      );
      setState(globalState);
      setLoading(false);
    });
  }, [can]);

  const context = useMemo(() => ([state, setState, reload]), [state]);

  if (loading) {
    return <SpinnerOverlay />;
  }

  return (
    <AppStateContext.Provider value={context}>
      {children}
    </AppStateContext.Provider>
  );
};

export default AppStateProvider;
