import React, { forwardRef } from 'react';
import { useScopedController } from 'react-hook-smartform';
import {
  MenuItem,
  Select as MuiSelect,
  FormControl,
  FormHelperText,
  InputLabel,
} from '@mui/material';

const Select = forwardRef(({
  name: fieldName,
  rules,
  label,
  helperText: defaultHelperText,
  children,
  defaultValue,
  nullable,
  nullableText = 'None',
  options: optionsArray = [],
  ...other
}, ref) => {
  const { field, fieldState: { invalid, error } } = useScopedController({
    defaultValue,
    name: fieldName,
    rules,
  });

  const required = !!(rules || {}).required;
  const helperText = invalid
    ? error.message
    : defaultHelperText;

  const options = children ?? [
    ...(nullable ? [(
      <MenuItem value="" key="null-value">
        <em>{nullableText}</em>
      </MenuItem>
    )] : []),
    ...optionsArray.map(({ key, label: optionLabel }) => (
      <MenuItem value={key} key={key}>
        {optionLabel}
      </MenuItem>
    )),
  ];

  return (
    <FormControl
      variant="outlined"
      required={required}
      error={!!error}
      fullWidth
      {...other}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        {...field}
        ref={ref}
        label={label}
      >
        {options}
      </MuiSelect>
      {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
});

export default Select;
