import React from 'react';
import Grid from '@mui/material/Grid';
import SubmitButton from '../../fields/SubmitButton';
import TextField from '../../fields/TextField';

const InstanceForm = ({ t }) => (
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12}>
      <TextField
        label={t('instance.name')}
        name="name"
        type="name"
        rules={{ required: t('form.requiredField') }}
        fullWidth
        autoFocus
      />
    </Grid>
    <Grid item xs={12}>
      <SubmitButton
        size="large"
        color="primary"
        variant="contained"
        fullWidth
      >
        {t('instance.next')}
      </SubmitButton>
    </Grid>
  </Grid>
);

export default InstanceForm;
