import React from 'react';
import { Form } from 'react-hook-smartform';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import LoginForm from '../../forms/LoginForm';
import InstanceForm from '../../forms/InstanceForm';

const useStyles = makeStyles(theme => ({
  form: {
    maxWidth: 450,
    padding: theme.spacing(1),
  },
}));

const AuthScreen = ({ onSubmit, step }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSubmit = (value, e, { reset }) => onSubmit(value)
    .finally(() => reset());

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item className={classes.form} direction="column" alignItems="center" container spacing={2} wrap="nowrap">
        <Grid item>
          <Form onSubmit={handleSubmit} hookParams={{ mode: 'onChange' }}>
            {step === 'instance'
              ? <InstanceForm t={t} />
              : <LoginForm t={t} />}
          </Form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AuthScreen;
