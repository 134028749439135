import {
  createTheme, responsiveFontSizes, StyledEngineProvider, ThemeProvider,
} from '@mui/material/styles';
import { flowRight } from 'lodash';
import React from 'react';
import { useAuth } from '../AuthProvider';

const createResponsiveTheme = flowRight(responsiveFontSizes, createTheme);

let theme = createTheme();

const fancyShadowMixin = {
  boxShadow:
    `0px 0px 0px rgba(0,0,0,0.12),
     0px 0px 0px rgba(0,0,0,0.12),
     0px 1px 6px rgba(0,0,0,0.12)`,
};

// NOTE: These theme settings are in effect after we're logged in
const AppThemeProvider = ({ children }) => {
  // BUG: Caret color is not working well enough on iOS wkWebView.
  // This is a combination of a long standing issue
  // https://developer.mozilla.org/en-US/docs/Web/CSS/caret-color#browser_compatibility
  // and the way we build a custom palette.
  // https://github.com/mui-org/material-ui/blob/512896973499adbbda057e7f3685d1b23cc02de9/packages/mui-material/src/OutlinedInput/OutlinedInput.js#L95
  // Setting it explicitly as an override works after we have instantiated the theme ie after login.

  const { instance: { palette } } = useAuth();

  theme = createResponsiveTheme({
    components: {
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            height: 70,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            borderRadius: theme.spacing(1),
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          h1: {
            fontSize: '2rem',
          },
          h6: {
            fontWeight: 'bold',
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: theme.spacing(1),
          },
        },
      },
      MuiListItemSecondaryAction: {
        styleOverrides: {
          root: {
            border: 'solid',
            borderWidth: '0px 0px 0px thin',
            borderColor: 'rgba(0, 0, 0, 0.12)',
            paddingLeft: theme.spacing(1.5),
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            caretColor: 'rgba(0,0,0,0.8)',
            background: theme.palette.background.default,
            borderRadius: theme.spacing(1),
          },
          input: {
            padding: theme.spacing(1.5),
          },
          inputMultiline: {
            padding: 0,
          },
        },
      },
    },
    palette,
  });

  theme.mixins.fancyShadow = fancyShadowMixin;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppThemeProvider;
