import { differenceInSeconds, parseISO } from 'date-fns';
import last from 'lodash/last';
import { useEffect, useState } from 'react';
import { useTimeTracking } from '../providers/TimeTrackingProvider';

const emptyTimecard = {
  rows: [],
};

const usePunchClockCounter = () => {
  const {
    timecard = emptyTimecard,
    paused,
  } = useTimeTracking();

  const { started_at: startDate = null, rows } = timecard || {};
  const [secondsCounter, setSecondsCounter] = useState(0);
  const [pausedCounter, setPausedCounter] = useState(0);
  const lastRow = last(rows);

  useEffect(() => {
    const startedAt = startDate && parseISO(startDate);

    // Initial counter
    setSecondsCounter(differenceInSeconds(new Date(), startedAt));

    const timerId = setInterval(
      () => setSecondsCounter(differenceInSeconds(new Date(), startedAt)),
      1000,
    );
    return () => clearInterval(timerId);
  }, [startDate]);

  useEffect(() => {
    if (!(paused && lastRow)) {
      return undefined;
    }

    const pausedDate = parseISO(lastRow.started_at);

    const timerId = setInterval(
      () => setPausedCounter(differenceInSeconds(new Date(), pausedDate)),
      1000,
    );
    return () => clearInterval(timerId);
  }, [lastRow, paused]);

  return {
    activeSeconds: secondsCounter,
    pausedSeconds: pausedCounter,
  };
};

export default usePunchClockCounter;
