export const MIN_PASSWORD_LENGTH = 7;
export const DATETIME_API_FORMAT = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSX';
export const DATE_API_FORMAT = 'yyyy-MM-dd';
export const DATE_FORMAT = 'dd.MM.yyyy';
export const DATE_FORMAT_SHORT = 'dd.MM';
export const TIME_FORMAT = 'HH:mm';
export const WEEKDAY_FORMAT = 'ccc';
export const TIME_API_FORMAT = 'HH:mm:ss';
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const CSRF_TOKEN_KEY = 'mywork-csrf';
export const SUBDOMAIN_KEY = 'mywork-subdomain';
export const PUNCH_PRESENT = 'present';
export const PUNCH_ABSENT = 'absent';
export const DATE_PICKER_MASK = '__.__.____';
export const DATETIME_PICKER_MASK = '__.__.____ __:__';

export const conceptColors = {
  ABSENCE: '#EA5A4D',
  CONTACTS: '#960018',
  DOCUMENTS: '#7DC481',
  FORMS: '#800080',
  GROUPS: '#288DD3',
  MEETING: '#9455BF',
  MESSAGES: '#EC7972',
  OTHER: '#9C7D21',
  REMINDERS: '#50C878',
  REMOTE_WORK: '#B84126',
  SCHEDULERS: '#F8DE7E',
  SENTIMENT: '#C269FF',
  SETTINGS: '#F8DE7E',
  TIME: '#7573F8',
  TRAINING: '#536E17',
  TRAVEL: '#B82657',
  USERS: '#D7722C',
  WORKING: '#3AC228',
};
