import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ResponseError from '../errors/ResponseError';

// Error data mutators.
const formatParams = (key, data) => {
  switch (key) {
    // TODO: implement when required
    default:
      return data;
  }
};

const useDefaultErrorHandler = (rethrow = false) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useCallback(async e => {
    if (!(e instanceof ResponseError)) {
      throw e;
    }
    const { response } = e;

    try {
      const body = await response.json();
      const { type, data, key } = body;

      switch (type) {
        // Errors returned using response_error.
        case 'error': {
          enqueueSnackbar(t(`errors:${key}`, formatParams(key, data)), { variant: 'error' });
          break;
        }
        // Field validation errors.
        case 'validation': {
          // We assume that errors looks like {"email":["validation.unique"]}.
          Object.keys(data)
            .forEach(name => data[name].forEach(error => enqueueSnackbar(t(`validation:${error}`, { input: { name } }), { variant: 'error' })));
          break;
        }
        default: {
          enqueueSnackbar(`${response.status} - ${response.statusText}`, { variant: 'error' });
        }
      }
    } catch (_) {
      switch (response.status) {
        case 401: {
          enqueueSnackbar(t('errors:common.unauthorized'), { variant: 'error' });
          break;
        }
        case 403: {
          enqueueSnackbar(t('errors:common.forbidden'), { variant: 'error' });
          break;
        }
        case 409: {
          enqueueSnackbar(t('errors:common.conflict'), { variant: 'error' });
          break;
        }
        default: {
          enqueueSnackbar(t('errors:common.unknown'), { variant: 'error' });
        }
      }
    }

    if (rethrow) {
      throw e;
    }
  }, [enqueueSnackbar, rethrow, t]);
};

export default useDefaultErrorHandler;
