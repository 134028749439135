import React, { Suspense } from 'react';
import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import TimeTrackingProvider from '../../providers/TimeTrackingProvider';
import AppBottomNavigation from '../AppBottomNavigation';
import AppBar from '../AppBar';
import useMuiRegistry from '../../builder/util/useMuiRegistry';
import FieldRegistryProvider from '../../builder/core/FieldRegistryProvider';
import Spinner from '../Spinner';

const useStyles = makeStyles(theme => ({
  content: {
    position: 'relative',
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
    background: theme.palette.grey[100], /* app background */
  },
}));

const AppContainer = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const muiRegistry = useMuiRegistry(t);

  return (
    <Suspense fallback={<Spinner />}>
      <FieldRegistryProvider registry={muiRegistry} t={t}>
        <TimeTrackingProvider>
          <Grid container direction="column" wrap="nowrap">
            <Grid
              item
              component="header"
            >
              <AppBar />
            </Grid>
            <Grid
              item
              className={classes.content}
              component="main"
            >
              {children}
            </Grid>
            <Grid
              item
              component="footer"
            >
              <AppBottomNavigation />
            </Grid>
          </Grid>
        </TimeTrackingProvider>
      </FieldRegistryProvider>
    </Suspense>
  );
};

export default AppContainer;
