import React from 'react';
import {
  ThemeProvider, StyledEngineProvider, createTheme,
} from '@mui/material/styles';

// NOTE: These theme settings are in effect until we're logged in
const DefaultThemeProvider = ({ children }) => {
  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            caretColor: 'rgba(0,0,0,0.8)',
          },
        },
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default DefaultThemeProvider;
