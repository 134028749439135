import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';

const AbsenceCreate = lazy(() => import('./AbsenceCreate'));
const AbsenceList = lazy(() => import('./AbsenceList'));
const AbsenceView = lazy(() => import('./AbsenceView'));
const AssetsView = lazy(() => import('./AssetsView'));
const CompanyView = lazy(() => import('./CompanyView'));
const ContactCreate = lazy(() => import('./ContactCreate'));
const ContactView = lazy(() => import('./ContactView'));
const ContactsList = lazy(() => import('./ContactsList'));
const DocumentCreate = lazy(() => import('./DocumentCreate'));
const DocumentView = lazy(() => import('./DocumentView'));
const DocumentsList = lazy(() => import('./DocumentsList'));
const Forbidden = lazy(() => import('../components/RouteErrors/Forbidden'));
const FormCreate = lazy(() => import('./FormCreate'));
const FormEdit = lazy(() => import('./FormEdit'));
const Forms = lazy(() => import('./Forms'));
const GroupCreate = lazy(() => import('./GroupCreate'));
const GroupView = lazy(() => import('./GroupView'));
const GroupsList = lazy(() => import('./GroupsList'));
const MessagesCreate = lazy(() => import('./MessagesCreate'));
const MessagesList = lazy(() => import('./MessagesList'));
const MessagesView = lazy(() => import('./MessagesView'));
const NotFound = lazy(() => import('../components/RouteErrors/NotFound'));
const PunchClock = lazy(() => import('./PunchClock'));
const PunchOut = lazy(() => import('./PunchOut'));
const ReminderList = lazy(() => import('./ReminderList'));
const SchedulerCreate = lazy(() => import('./SchedulerCreate'));
const SchedulerList = lazy(() => import('./SchedulerList'));
const SchedulerView = lazy(() => import('./SchedulerView'));
const Settings = lazy(() => import('./Settings'));
const Start = lazy(() => import('./Start'));
const SurveyView = lazy(() => import('./SurveyView'));
const Surveys = lazy(() => import('./Surveys'));
const TimecardEdit = lazy(() => import('./TimecardEdit'));
const TimecardList = lazy(() => import('./TimecardList'));
const UserCreate = lazy(() => import('./UserCreate'));
const UserSentiment = lazy(() => import('./UserSentiment'));
const UserView = lazy(() => import('./UserView'));
const UsersList = lazy(() => import('./UsersList'));

const RestrictedRoute = ({ permission, ...props }) => {
  const { can } = useAuth();

  if (can(permission)) {
    return <Route {...props} />;
  }

  return <Forbidden />;
};

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Start} />
    <Route exact path="/assets" component={AssetsView} />
    <Route exact path="/company" component={CompanyView} />
    <Route exact path="/time" component={PunchClock} />
    <Route exact path="/time/confirm" component={PunchOut} />
    <Route exact path="/messages" component={MessagesList} />
    <Route exact path="/users" component={UsersList} />
    <Route exact path="/groups" component={GroupsList} />
    <Route exact path="/groups/create" component={GroupCreate} />
    <Route exact path="/groups/:id/:op?" component={GroupView} />
    <RestrictedRoute exact path="/users/create" component={UserCreate} permission="administer_users" />
    <Route exact path="/users/:id/:op?" component={UserView} />
    <Route exact path="/contacts" component={ContactsList} />
    <RestrictedRoute exact path="/contacts/create" component={ContactCreate} permission="administer_contacts" />
    <Route exact path="/contacts/:id/:op?" component={ContactView} />
    <RestrictedRoute exact path="/settings/:tab?" component={Settings} permission="administer_settings" />
    <Route exact path="/messages/create" component={MessagesCreate} />
    <Route exact path="/messages/:id" component={MessagesView} />
    <Route exact path="/documents" component={DocumentsList} />
    <Route exact path="/documents/create" component={DocumentCreate} />
    <Route exact path="/documents/:id/:op?" component={DocumentView} />
    <Route exact path="/absence" component={AbsenceList} />
    <Route exact path="/absence/create" component={AbsenceCreate} />
    <Route exact path="/absence/:id/:op?" component={AbsenceView} />
    <Route exact path="/time/log" component={TimecardList} />
    <Route exact path="/reminders" component={ReminderList} />
    <Route exact path="/surveys/:tab(submitted)?" component={Surveys} />
    <Route exact path="/surveys/:id" component={SurveyView} />
    <RestrictedRoute exact path="/schedulers" component={SchedulerList} permission="administer_schedulers" />
    <RestrictedRoute exact path="/schedulers/create" component={SchedulerCreate} permission="administer_schedulers" />
    <RestrictedRoute exact path="/schedulers/:id" component={SchedulerView} permission="administer_schedulers" />
    <RestrictedRoute exact path="/time/log/:id" component={TimecardList} permission="administer_timecards" />
    <RestrictedRoute exact path="/timecards/:id" component={TimecardEdit} permission="administer_timecards" />
    <RestrictedRoute exact path="/sentiment" component={UserSentiment} permission="administer_timecards" />
    <RestrictedRoute exact path="/forms" component={Forms} permission="administer_forms" />
    <RestrictedRoute exact path="/forms/create" component={FormCreate} permission="administer_forms" />
    <RestrictedRoute exact path="/forms/:formId/:op(entries)?/:entryId?" component={FormEdit} permission="administer_forms" />
    <Route path="*">
      <NotFound />
    </Route>
  </Switch>
);

export default Routes;
