import {
  format, parseISO, formatDistanceToNow, differenceInMinutes, isThisYear, isToday,
} from 'date-fns';
import { DATE_FORMAT, DATE_FORMAT_SHORT, TIME_FORMAT } from '../../config/constants';

const supportedLocales = {
  fi: () => import('date-fns/locale/fi'),
  sv: () => import('date-fns/locale/sv'),
  en: () => import('date-fns/locale/en-GB'),
};

let currentLocale;
export const getCurrentLocale = () => currentLocale;

export const localizedFormat = (date, formatString = 'PP') => format(
  date,
  formatString,
  { locale: currentLocale },
);

export const localizedMessageDate = dateStr => {
  const date = parseISO(dateStr);

  if (differenceInMinutes(new Date(), date) < 60) {
    return formatDistanceToNow(date, { locale: currentLocale });
  }
  if (isToday(date)) {
    return format(date, TIME_FORMAT);
  }
  if (isThisYear(date)) {
    return format(date, `${TIME_FORMAT} ${DATE_FORMAT_SHORT}`);
  }

  return format(date, `${TIME_FORMAT} ${DATE_FORMAT}`);
};

// Lazy date locale imports.
const setDateLocale = locale => {
  if (Object.prototype.hasOwnProperty.call(supportedLocales, locale)) {
    return supportedLocales[locale]().then(module => {
      currentLocale = module.default;
    });
  }
  throw new Error(`Unsupported date locale: ${locale}`);
};

export default setDateLocale;
