import ResponseError from '../errors/ResponseError';
import { getCsrfToken } from './csrf';
import { getSubdomain } from './subdomain';

export const getBaseUrl = subdomain => `${process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http'}://${subdomain || getSubdomain()}.${process.env.REACT_APP_API_ROOT}`;

export const getSocketUrl = subdomain => `${process.env.REACT_APP_HTTPS === 'true' ? 'wss' : 'ws'}://${subdomain || getSubdomain()}.${process.env.REACT_APP_WS_ROOT}`;

const request = (
  uri,
  {
    isLocalRequest = true, parseJSON = true, headers, ...params
  } = {},
) => fetch(
  isLocalRequest ? `${getBaseUrl()}/${uri}` : uri,
  {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    redirect: 'error',
    ...params,
    headers: headers ? {
      ...headers,
      'X-CSRF-TOKEN': getCsrfToken(),
    } : {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-TOKEN': getCsrfToken(),
    },
  },
).then(response => {
  if (!response.ok) {
    throw new ResponseError(response);
  }
  return parseJSON
    ? response.text().then(text => text && JSON.parse(text))
    : response;
});

export default request;
