import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React, {
  createContext, useContext, useEffect, useState, useMemo,
} from 'react';
import resources from '../../locales';
import setDateLocale from './setDateLocale';
import SpinnerOverlay from '../../components/SpinnerOverlay';

// We need to have a knowledge of languages before we even know which instance to work against.
const DEFAULT_LANGUAGES = ['en', 'fi', 'sv'];

const LanguageContext = createContext();

export const useLanguages = () => useContext(LanguageContext);

export const setUiLanguage = id => i18n.changeLanguage(id);

const LanguagesProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [languages = [], setLanguages] = useState(DEFAULT_LANGUAGES);

  useEffect(() => {
    const whitelist = languages;
    const [fallbackLng] = whitelist;

    i18n
      .use(LanguageDetector)
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
        fallbackLng,
        whitelist,
        resources,
        defaultNS: 'ui',
        fallbackNS: 'ui',
        returnEmptyString: false,
        detection: {
          lookupLocalStorage: 'languageCode',
          checkWhitelist: true,
        },
        interpolation: {
          skipOnVariables: false,
        },
      });

    setDateLocale(i18n.resolvedLanguage).then(() => setLoading(false));
  }, [languages]);

  const context = useMemo(() => ({
    languages,
    setLanguages,
  }), [languages]);

  if (loading) {
    return <SpinnerOverlay />;
  }

  return (
    <LanguageContext.Provider value={context}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguagesProvider;
