import React from 'react';
import Grid from '@mui/material/Grid';
import { Button, Typography } from '@mui/material';
import SubmitButton from '../../fields/SubmitButton';
import TextField from '../../fields/TextField';
import { useAuth } from '../../providers/AuthProvider';

const AppTitle = () => {
  const { instance: { name } } = useAuth();

  return (
    <Typography variant="h5" align="center">
      {name}
    </Typography>
  );
};

const ResetInstance = ({ t }) => {
  const { clearInstance } = useAuth();
  return (
    <Button onClick={clearInstance} fullWidth>
      {t('instance.changeInstance')}
    </Button>
  );
};

const LoginForm = ({ t }) => (
  <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12}>
      <AppTitle />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label={t('users.email')}
        name="email"
        type="email"
        rules={{ required: t('form.requiredField') }}
        fullWidth
        autoFocus
      />
    </Grid>
    <Grid item xs={12}>
      <TextField
        label={t('users.password')}
        name="password"
        type="password"
        rules={{ required: t('form.requiredField') }}
        fullWidth
      />
    </Grid>
    <Grid item xs={12}>
      <SubmitButton
        size="large"
        color="primary"
        variant="contained"
        fullWidth
      >
        {t('users.login')}
      </SubmitButton>
    </Grid>
    <Grid item xs={12}>
      <ResetInstance t={t} />
    </Grid>
  </Grid>
);

export default LoginForm;
