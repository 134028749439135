import sv from './sv';
import fi from './fi';
import en from './en';

const resources = {
  sv,
  fi,
  en,
};

export default resources;
