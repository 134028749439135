import React from 'react';
import { SnackbarProvider } from 'notistack';
import {
  BrowserRouter as Router,
} from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import ThemeProvider from './providers/ThemeProvider';
import DefaultThemeProvider from './providers/DefaultThemeProvider';
import AppContainer from './components/AppContainer';
import UpdateNotifier from './components/UpdateNotifier';
import Routes from './routes';
import AuthProvider from './providers/AuthProvider';
import AppStateProvider from './providers/AppStateProvider';
import SocketProvider from './providers/SocketProvider';
import BadgesProvider from './providers/BadgesProvider';
import LanguagesProvider from './providers/LanguagesProvider';

const App = () => (
  <DefaultThemeProvider>
    <LanguagesProvider>
      <CssBaseline />
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <UpdateNotifier />
        <AuthProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider>
              <Router>
                <SocketProvider>
                  <AppStateProvider>
                    <BadgesProvider>
                      <AppContainer>
                        <Routes />
                      </AppContainer>
                    </BadgesProvider>
                  </AppStateProvider>
                </SocketProvider>
              </Router>
            </ThemeProvider>
          </StyledEngineProvider>
        </AuthProvider>
      </SnackbarProvider>
    </LanguagesProvider>
  </DefaultThemeProvider>
);

export default App;
