import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
  progress: {
    margin: 'auto',
    display: 'block',
  },
}));

const Spinner = ({ className, ...other }) => {
  const classes = useStyles();

  return (
    <CircularProgress
      className={clsx(className, classes.progress)}
      thickness={7}
      {...other}
    />
  );
};

export default Spinner;
