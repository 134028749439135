import SettingsIcon from '@mui/icons-material/Settings';
import MuiAppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { getBaseUrl } from '../../util/request';

const useStyles = makeStyles({
  image: {
    maxHeight: 48,
    maxWidth: 128,
  },
});

const AppBar = () => {
  const trigger = useScrollTrigger();
  const { instance: { name, logo = [] }, can } = useAuth();
  const classes = useStyles();

  const [first] = logo;

  return (
    <Slide appear={false} direction="down" in={!trigger} position="static">
      <MuiAppBar position="fixed" sx={{ height: 65 }}>
        <Toolbar disableGutters>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
            sx={{ pt: 0.5 }}
          >
            <Grid
              item
              container
              spacing={1}
              alignItems="center"
              sx={{ pl: 0.5 }}
            >
              { first ? (
                <Grid item>
                  <Link component={RouterLink} to="/">
                    <img
                      src={`${getBaseUrl()}/images/logo`}
                      alt={name}
                      className={classes.image}
                    />
                  </Link>
                </Grid>
              ) : null}
              <Hidden smDown>
                <Grid item>
                  <Typography variant="h5">{name}</Typography>
                </Grid>
              </Hidden>
            </Grid>
            <Grid item container spacing={1} justifyContent="flex-end" alignItems="center">
              { can('administer_settings') ? (
                <Grid item>
                  <IconButton color="inherit" component={RouterLink} to="/settings" size="large">
                    <SettingsIcon />
                  </IconButton>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Toolbar>
      </MuiAppBar>
    </Slide>
  );
};

export default AppBar;
