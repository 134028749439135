import { useReducer, useEffect } from 'react';
import { useSocketProvider } from '../providers/SocketProvider';

const replaceState = Symbol('Replace state action type');

const useSocketReducer = (reducer, defaultState) => {
  const extendedReducer = (state, action) => ((action.type === replaceState)
    ? action.state
    : reducer(state, action));

  const [state, dispatch] = useReducer(extendedReducer, defaultState);
  const { registerDispatcher, unregisterDispatcher } = useSocketProvider();

  useEffect(() => {
    registerDispatcher(dispatch);
    return () => unregisterDispatcher(dispatch);
  }, [dispatch, registerDispatcher, unregisterDispatcher]);

  useEffect(() => {
    dispatch({
      type: replaceState,
      state: defaultState,
    });
  }, [defaultState]);

  return [state, dispatch];
};

export default useSocketReducer;
