import last from 'lodash/last';
import { useSnackbar } from 'notistack';
import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import formatApiDateTime from '../../util/formatApiDateTime';
import request from '../../util/request';
import useDefaultErrorHandler from '../../util/useDefaultErrorHandler';
import { useAuth } from '../AuthProvider';

const TimeTrackingContext = createContext();

const postPunchclock = body => request('punchclock', { method: 'POST', body: JSON.stringify(body) });
const getInitialWorkphase = () => request('workphases/default');
const postPunchclockStop = body => request('punchclock/stop', { method: 'POST', body: JSON.stringify(body) });
const postLog = body => request('punchclock/log', { method: 'POST', body: JSON.stringify(body) });

const TimeTrackingProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { user: { timecard: defaultTimecard } } = useAuth();

  const [timecard, setTimecard] = useState(defaultTimecard);
  const [currentPhase, setcurrentPhase] = useState();
  const [paused, setPaused] = useState(false);
  const errorHandler = useDefaultErrorHandler();

  useEffect(() => {
    const latestPhase = last(timecard?.rows);
    setPaused(latestPhase?.workphase_id === 'break');
  }, [timecard]);

  useEffect(
    () => getInitialWorkphase()
      .then(response => setcurrentPhase(response.workphase_id)),
    [],
  );

  const punchIn = useCallback(body => postPunchclock(body).then(response => {
    enqueueSnackbar(t('punchClock.punchedIn'), { variant: 'info' });
    setTimecard(response);
  }), [enqueueSnackbar, t]);

  const punchOut = useCallback(body => postPunchclockStop(body).then(() => {
    enqueueSnackbar(t('punchClock.punchedOut'), { variant: 'info' });
    setTimecard(null);
    getInitialWorkphase()
      .then(response => setcurrentPhase(response.workphase_id));
  }), [enqueueSnackbar, t]);

  const setCurrentPhase = useCallback(
    id => postLog({
      workphase_id: id,
      started_at: formatApiDateTime(new Date()),
    }).then(response => {
      setTimecard(
        ({ rows: previousRows, ...other }) => ({ rows: [...previousRows, response], ...other }),
      );
    }).catch(errorHandler)
      .finally(() => setcurrentPhase(id)),
    [errorHandler],
  );

  const pause = useCallback(() => setCurrentPhase('break'), [setCurrentPhase]);

  const resume = useCallback(() => {
    const previousPhase = timecard.rows.at(-2);

    setCurrentPhase(previousPhase?.workphase_id);
  }, [setCurrentPhase, timecard]);

  const context = useMemo(
    () => ({
      timecard,
      pause,
      paused,
      resume,
      punchIn,
      punchOut,
      currentPhase,
      setCurrentPhase,
    }),
    [timecard, pause, paused, resume, punchIn, punchOut, currentPhase, setCurrentPhase],
  );

  return (
    <TimeTrackingContext.Provider value={context}>
      {children}
    </TimeTrackingContext.Provider>
  );
};

export const useTimeTracking = () => useContext(TimeTrackingContext);

export default TimeTrackingProvider;
