import { useScopedController } from 'react-hook-smartform';
import MuiTextField from '@mui/material/TextField';
import React, { forwardRef } from 'react';

const TextField = forwardRef((props, ref) => {
  const {
    name: fieldName,
    defaultValue = '',
    helperText,
    rules,
    ...other
  } = props;

  const { field: { value, ...field }, fieldState: { error } } = useScopedController({
    defaultValue,
    name: fieldName,
    rules,
  });

  return (
    <MuiTextField
      required={!!rules?.required}
      error={!!error}
      value={value || ''}
      ref={ref}
      helperText={error?.message ?? helperText}
      {...other}
      {...field}
    />
  );
});

export default TextField;
