import { FormControl, FormControlLabel } from '@mui/material';
import React from 'react';

const LabelWrapper = props => {
  const {
    children,
    props: { label = '', rules },
    fieldState: { invalid },
  } = props;
  const required = !!(rules || {}).required;

  return (
    <FormControl
      required={required}
      error={invalid}
    >
      <FormControlLabel
        label={`${label}${required ? ' *' : ''}`}
        control={children}
        sx={{ color: invalid ? 'error.main' : 'inherit' }}
      />
    </FormControl>
  );
};

export default LabelWrapper;
