import React, {
  createContext, useContext, useState, useEffect, useMemo,
} from 'react';
import { useSnackbar } from 'notistack';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { clearSubdomain, getSubdomain } from '../../util/subdomain';
import { setUiLanguage, useLanguages } from '../LanguagesProvider';
import api from './authApi';
import AuthScreen from '../../components/AuthScreen';
import SpinnerOverlay from '../../components/SpinnerOverlay';
import request from '../../util/request';
import useDefaultErrorHandler from '../../util/useDefaultErrorHandler';

const loadInstance = () => {
  const subdomain = getSubdomain();
  return subdomain ? api.instance(subdomain) : Promise.reject();
};

// Early start.
const initialInstance = loadInstance();

const initialAuthentication = initialInstance.then(
  () => api.authenticate().catch(() => null),
);

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [apnsRegistered, setApnsRegistered] = useState(false);
  const [instance, setInstance] = useState(null);
  const [user, setUser] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { setLanguages } = useLanguages();
  const { t } = useTranslation();

  const clearInstance = () => {
    setInstance(null);
    clearSubdomain();
  };

  const reloadInstance = () => loadInstance().then(setInstance);
  const errorHandler = useDefaultErrorHandler();

  useEffect(() => {
    // Resolve current instance.
    initialInstance.then(setInstance).catch(e => {
      setInstance(null);
      clearSubdomain();
    });

    // Initial authentication.
    initialAuthentication
      .then((response = {}) => {
        setUser(get(response, 'user'));
        setApnsRegistered(get(response, 'apns_registered', false));
      })
      .finally(() => setLoading(false));
  }, [setLanguages]);

  useEffect(() => {
    // Once we have an instance we can query that backend for languages
    if (instance) {
      request('languages')
        .then(setLanguages);
    }
  }, [instance, setLanguages]);

  useEffect(() => {
    if (user) {
      setUiLanguage(user.language_id);
    }
  }, [user]);

  const login = ({ email, password }) => api.login({ email, password })
    .then((response = {}) => {
      setUser(get(response, 'user'));
      setApnsRegistered(get(response, 'apns_registered', false));
      enqueueSnackbar(t('users.welcomeBack', { name: response.first_name }), { variant: 'success' });
    })
    .catch(errorHandler);

  const initialize = ({ name }) => api.instance(name)
    .then(setInstance)
    .catch(errorHandler);

  const context = useMemo(() => {
    const logout = () => api.logout().then(() => {
      enqueueSnackbar(t('users.loggedOut'), { variant: 'info' });
      setUser(undefined);
      setApnsRegistered(false);
    }).catch(errorHandler);

    const update = (body, id) => api.update(body, id).then(response => {
      enqueueSnackbar(t('users.updated'), { variant: 'info' });
      setUser(response);
    }).catch(errorHandler);

    const updateAccount = body => api.updateAccount(body).then(response => {
      enqueueSnackbar(t('users.accountUpdated'), { variant: 'info' });
      setUser(response);
    }).catch(errorHandler);

    const can = permission => {
      const { permissions = [] } = user;
      if (permission === 'authenticated') {
        return !!(user && user.id);
      }
      return permissions.indexOf(permission) !== -1;
    };

    return ({
      apnsRegistered,
      updateAccount,
      instance,
      clearInstance,
      reloadInstance,
      user,
      can,
      update,
      logout,
    });
  }, [apnsRegistered, enqueueSnackbar, errorHandler, instance, t, user]);

  if (loading) {
    return <SpinnerOverlay />;
  }

  return (
    <AuthContext.Provider value={context}>
      { user
        ? children
        : (
          <AuthScreen
            {...(instance
              ? { step: 'login', onSubmit: login }
              : { step: 'instance', onSubmit: initialize }
            )}
          />
        )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
