import React from 'react';
import Grid from '@mui/material/Grid';
import Spinner from '../Spinner';

const SpinnerOverlay = () => (
  <Grid container direction="column" justifyContent="center" alignItems="center">
    <Grid item>
      <Spinner />
    </Grid>
  </Grid>
);

export default SpinnerOverlay;
