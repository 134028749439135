import { useMemo } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListIcon from '@mui/icons-material/List';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import StarRateIcon from '@mui/icons-material/StarRate';
import Checkbox from '../fields/Checkbox';
import Rating from '../fields/Rating';
import TextBlock from '../fields/TextBlock';
import TextField from '../fields/TextField';
import SlugField from '../fields/SlugField';
import LabelWrapper from './LabelWrapper';
import Switch from '../fields/Switch';
import Select from '../fields/Select';
import TableField from '../fields/TableField';
import DatePicker from '../fields/DatePicker';

const useMuiRegistry = t => useMemo(() => {
  const labelField = {
    type: 'text',
    defaultValue: '',
    props: [
      {
        name: 'label',
        label: t('formBuilder.baseSchema.label'),
        helperText: t('formBuilder.baseSchema.labelHelper'),
        rules: {
          required: t('formBuilder.validation.required'),
        },
      },
    ],
  };

  const nameField = {
    type: 'slug',
    defaultValue: '',
    props: [
      {
        name: 'name',
        source: 'label',
        depth: 3,
      },
    ],
  };

  const requiredField = {
    type: 'switch',
    defaultValue: undefined,
    props: [
      {
        name: 'rules',
        on: {
          required: t('formBuilder.validation.required'),
        },
        off: undefined,
        isChecked: val => !!val?.required,
        label: t('formBuilder.baseSchema.required'),
      },
    ],
  };

  const baseSchema = [
    labelField,
    nameField,
    requiredField,
  ];

  return {
    fields: {
      text: {
        name: t('formBuilder.fields.text.name'),
        widget: TextField,
        icon: TextFieldsIcon,
        schema: [
          ...baseSchema,
          {
            type: 'text',
            defaultValue: '',
            props: [
              {
                name: 'helperText',
                label: t('formBuilder.fields.text.helperText'),
              },
            ],
          }, {
            type: 'select',
            defaultValue: 'text',
            props: [
              {
                label: t('formBuilder.fields.text.type'),
                name: 'type',
                options: [
                  {
                    label: t('formBuilder.fields.text.typeText'),
                    key: 'text',
                  },
                  {
                    label: t('formBuilder.fields.text.multiline'),
                    key: 'multiline',
                  },
                  {
                    label: t('formBuilder.fields.text.typeEmail'),
                    key: 'email',
                  },
                  {
                    label: t('formBuilder.fields.text.typeNumber'),
                    key: 'number',
                  },
                ],
              },
            ],
          },
        ],
        defaultValue: '',
        defaultProps: {
          variant: 'outlined',
          fullWidth: true,
        },
      },
      checkbox: {
        name: t('formBuilder.fields.checkbox.name'),
        widget: Checkbox,
        icon: CheckBoxIcon,
        schema: [
          ...baseSchema,
        ],
        defaultValue: null,
        defaultProps: {
          wrapper: LabelWrapper, // Wraps field in FormControlLabel
        },
      },
      rating: {
        name: t('formBuilder.fields.rating.name'),
        widget: Rating,
        icon: StarRateIcon,
        schema: [
          ...baseSchema,
        ],
        defaultValue: null,
      },
      switch: {
        name: t('formBuilder.fields.switch.name'),
        widget: Switch,
        icon: ToggleOnIcon,
        schema: [
          ...baseSchema,
        ],
        defaultValue: null,
        defaultProps: {
          wrapper: LabelWrapper,
        },
      },
      select: {
        name: t('formBuilder.fields.select.name'),
        widget: Select,
        icon: ArrowDropDownIcon,
        schema: [
          ...baseSchema,
          {
            type: 'table',
            defaultValue: [],
            props: [
              {
                name: 'options',
                label: t('formBuilder.fields.select.options'),
                columns: [
                  {
                    name: t('formBuilder.fields.select.optionLabel'),
                    key: 'label',
                    withSlug: true,
                  },
                ],
              },
            ],
          },
        ],
        defaultValue: null,
        defaultProps: {},
      },
      datePicker: {
        name: t('formBuilder.fields.datePicker.name'),
        widget: DatePicker,
        icon: CalendarTodayIcon,
        schema: [
          ...baseSchema,
        ],
        defaultValue: null,
        defaultProps: {},
      },
      slug: {
        hide: true,
        widget: SlugField,
      },
      table: {
        name: t('formBuilder.fields.table.name'),
        widget: TableField,
        icon: ListIcon,
        schema: [
          labelField,
          nameField,
          {
            type: 'table',
            defaultValue: [],
            props: [
              {
                name: 'columns',
                label: t('formBuilder.fields.table.columns'),
                columns: [
                  {
                    name: t('formBuilder.fields.table.columnLabel'),
                    key: 'label',
                    withSlug: true,
                  },
                ],
              },
            ],
          },
        ],
        defaultValue: [],
        defaultProps: {
          columns: [],
        },
      },
      textblock: {
        name: t('formBuilder.fields.textblock.name'),
        widget: TextBlock,
        icon: TextFieldsIcon,
        schema: [
          labelField,
          nameField,
          {
            type: 'text',
            defaultValue: '',
            props: [
              {
                name: 'text',
                label: t('formBuilder.fields.textblock.text'),
                multiline: true,
              },
            ],
          }, {
            type: 'select',
            defaultValue: 'body1',
            props: [
              {
                label: t('formBuilder.fields.textblock.variant'),
                name: 'variant',
                options: [
                  {
                    label: 'body1',
                    key: 'body1',
                  },
                  {
                    label: 'body2',
                    key: 'body2',
                  },
                  {
                    label: 'subtitle1',
                    key: 'subtitle1',
                  },
                  {
                    label: 'subtitle2',
                    key: 'subtitle2',
                  },
                  {
                    label: 'h1',
                    key: 'h1',
                  },
                ],
              },
            ],
          },
        ],
      },
    },
  };
}, [t]);

export default useMuiRegistry;
