import DescriptionIcon from '@mui/icons-material/Description';
import HomeIcon from '@mui/icons-material/Home';
import MessageIcon from '@mui/icons-material/Message';
import PauseIcon from '@mui/icons-material/Pause';
import TimerIcon from '@mui/icons-material/Timer';
import Badge from '@mui/material/Badge';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useBadges } from '../../providers/BadgesProvider';
import usePunchClockCounter from '../../util/usePunchClockTimer';

const BottomNavigationLink = ({ value, ...props }) => (
  <BottomNavigationAction
    {...props}
    component={Link}
    to={value}
  />
);

const useStyles = makeStyles(theme => ({
  '@keyframes blinker': {
    from: { opacity: 1 },
    '50%': { opacity: 0.5 },
    to: { opacity: 1 },
  },
  paused: {
    animationName: '$blinker',
    animationDuration: '2s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
  },
  root: {
    zIndex: theme.zIndex.appbar + 1,
    position: 'relative', /* so shadow works */
    boxShadow: theme.shadows[12],
  },
}));

const displayTime = seconds => {
  if (!seconds) {
    return null;
  }
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  return hours < 1
    ? `${(minutes % 60).toString().padStart(2, '0')}:${(seconds % 60).toString().padStart(2, '0')}`
    : `${hours.toString().padStart(2, '0')}:${(minutes % 60).toString().padStart(2, '0')}`;
};

const AppBottomNavigation = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { activeSeconds, paused, pausedSeconds } = usePunchClockCounter();
  const classes = useStyles();
  const { messageCount } = useBadges();

  return (
    <BottomNavigation
      value={pathname}
      showLabels
      className={classes.root}
    >
      <BottomNavigationLink
        value="/"
        label={t('navigation.home')}
        icon={<HomeIcon />}
      />
      <BottomNavigationLink
        value="/messages"
        label={t('navigation.messages')}
        icon={(
          <Badge badgeContent={messageCount} max={99} color="secondary">
            <MessageIcon />
          </Badge>
        )}
      />
      <BottomNavigationLink
        value="/documents"
        label={t('navigation.documents')}
        icon={<DescriptionIcon />}
      />
      <BottomNavigationLink
        value="/time"
        label={displayTime(paused ? pausedSeconds : activeSeconds) || t('navigation.punchClock')}
        icon={paused ? <PauseIcon /> : <TimerIcon />}
        className={clsx(paused && classes.paused)}
      />
    </BottomNavigation>
  );
};

export default AppBottomNavigation;
